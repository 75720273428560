<template>
    <div class="row mt-2">
		<label class="col-4 col-form-label" for="horse_transpondeur">{{ $t('horse.numero_transpondeur') }}</label>	
        <div class="col-8">
            <div class="row no-gutters">
                <e-copybox 
                    boxed
                    id="horse_transpondeur" 
                    ref="horse_fiche_transpondeur" classes="col" 
                    :value.sync="horse_transpondeur" 
                    @keyup="$emit('update:horse_prop_transpondeur', horse_transpondeur)"
                    :readonly="!editable"
                ></e-copybox>
                <button v-if="horse_transpondeur && !readonly && modified" size="sm" class="btn btn-primary col-auto p-1" type="button" @click.prevent="confirm_microship"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse />{{ $t('global.valider') }}</button>
            </div>
        </div>

    <ModalConfirm
        ref="confirm_microship"
        id="confirm_microship_c"
        :icon="['far', 'save']"
        :icon_accept="['fal', 'level-down']"
        :text_title="$t('modal.horse.validation_microship')"
        :text_question="$t('modal.horse.text_validation_microship')"
        :text_button_ok="$t('modal.general.sauvegarder')"
        :text_button_accept="$t('modal.general.ok')"
        :text_button_cancel="$t('modal.general.annuler')"
        :callback_validation="confirmSwitchSaveMicroshipModal"
        :callback_cancel="cancelSwitchMicroshipModal"
        :processing="processing_microship"
    />
    </div>
</template>

<script type="text/javascript">
	import HorseMixin from "@/mixins/Horse.js";

	export default {
		mixins: [HorseMixin],
		props: [
            'horse_id',
            'horse_prop_transpondeur',
            'editable',
            'readonly'
		],
		data () {
			return {
                horse_transpondeur: '',
                processing: false,
                processing_microship: false,
                modified: false
			}
		},
		created() {
			this.init_component()
		},
		methods: {
			async init_component() {
                this.horse_transpondeur = this.horse_prop_transpondeur
			},
            async confirm_microship() {
                this.$refs.confirm_microship.openModal()
			},
            cancelSwitchMicroshipModal() {
				this.$refs.confirm_microship.closeModal()
			},
			async confirmSwitchSaveMicroshipModal() {
                if(this.processing_microship == false && this.processing == false) {
					this.processing_microship = true
                    this.processing = true

                    const result = await this.getSireByMicroship(this.horse_transpondeur)
                    if(result && result.code_retour == 0) {
                        this.updateHorseTranspondeur(this.horse_id, this.horse_transpondeur)
                        
                        const sire_complet = result.retour

                        const sire = sire_complet.substr(0, 8)
                        const cle = sire_complet.substr(9, 1)

                        const horse_exists = await this.sireAlreadyExists(sire, cle)
                        if(!horse_exists) {
                            const input = sire + cle

                            const result = await this.search_sire_horse_manual(input, this.horse_id)
                            if(result.code_retour === 0) {
                                await this.$sync.force(true)
                            }
                            else {
                                let error_message = ""

                                if(this.tab_trad_manual_add.indexOf(result) > -1) {
                                    error_message = this.getTrad("horse.introuvable")
                                }
                                error_message = this.getTrad("error."+(result=="" ? "UKE" : result))
                                this.failureToast(error_message, true)
                            }
                        }
                        else {
                            this.failureToast(this.getTrad("horse.already_exists"), true)
                        }
                    }
                    else {
                        await this.updateHorse(this.horse_id, {'horse_transpondeur': this.horse_transpondeur})
                        this.infoToast('toast.check_transpondeur')
                    }

                    this.$refs.confirm_microship.closeModal()
                    this.processing_microship = false
                    this.processing = false
                    this.$emit('submit')
                }
                this.modified = false;
			},
		},
        watch: {
			horse_prop_transpondeur() {
				this.init_component()
			},
            horse_transpondeur(val) {
                if (val != this.horse_prop_transpondeur) {
                    this.modified = true
                }
            }
        },
        components: {
            ModalConfirm: () => import('GroomyRoot/components/Modals/ModalConfirm')
        }
	}
</script>
